import React, { Component } from "react"
import CookieConsent from "react-cookie-consent"
import "./cookies-info-alert.scss"

class CookiesAlert extends Component {
  render() {
    return (
      <>
        <CookieConsent
          location="bottom"
          buttonText="OK"
          hideOnAccept={true}
          acceptOnScroll={true}
          style={{ background: "#343a40" }}
          buttonStyle={{
            color: "#fff",
            border: "1px solid #fff",
            background: "transparent",
          }}
        >
          <p className="m-0 small">Наш сайт использует файлы cookie, продолжая работу с
          imperia-studio.ru, вы подтверждаете использование сайтом cookies
          вашего браузера. Вы также можете запретить сохранение файлов cookie в
          настройках браузера.</p>
        </CookieConsent>
      </>
    )
  }
}

export default CookiesAlert
